body {
  font-family: 'Nunito', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* color: #013950; */
  background-color: white;
  font-size: 1.2rem;
}

html, body {
  overflow-x: hidden;
}

.p {
  font-size: 18px;
}

.v-logo {
  float: left;
}

.nav-sign-up-button {
  font-size: 1.2rem;
}

.nav-link {
  padding-left: 1rem;
  padding-right: 1rem;
  color: black !important;
}

.nav-link:hover {
  border-bottom: 4px solid #013950;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.main-header {
  padding-left: 20px;
  box-shadow: 0px 2px 5px #f0efee;
}

.green-nav {
  background-color: #DDF8E2;
}

.blue-nav {
  background-color: #013950;
}

.banner-row {
  padding: 0;
}

/* .text-over-img {
  padding-left: 50px;
  padding-top: 30px;
  z-index: 1;
  position: relative;
  color: white;
  background: url(./tmpImages/background_website_2.jpg);
  background-size: cover;
  background-position: center;
  height: 800px;
} */

.home-top-text {
  margin-bottom: 50px;
  letter-spacing: 1px;
  font-size: 1.3rem;
}

.value-prop-column {
  padding-left: 10%;
}

.blogpost-card {
  margin-top: 10px;
  background-color: #013950;
  color: white;
  border-radius: 25px;
}

.blogpost-card-img {
  border: 1px solid white;
}

.posting-links {
  color: #013950;
  margin: 5px;
}

.angellist-icon {
  margin-left: 5px;
}

.company-card-image {
  margin-top: 30px;
  margin-left: 20px;
  margin-right: 20px;
  width: 40%;
  box-shadow: 5px 5px 5px #999;
  background-color: white;
}

.company-search-title {
  font-size: 1.75rem;
}

.new-button {
  background-color: #F59070;
}

.company-card-col {
  margin-top: 40px;
}

.category-button {
  background-color: #013950;
  border-color: #013950;
  box-shadow: 2px 2px 2px #999;
}

.category-button:hover, .category-button:active, .category-button:focus {
  color: #ffffff !important;
  background-color: #F59070 !important;
  border-color: #F59070 !important;
}

.checkit-button {
  display: block;
  width: 150px;
  margin: 0 auto;
  margin-top: 20px;
}

.thanks-copy {
  /* padding: 30px; */
  margin-left: 25px;
  margin-right: 50px;
  /* border: 1px solid rgba(0,0,0,.125); 
  border-radius: 25px; */
}

.opportunity-button {
  margin-top: 20px;
  margin-left: 10px;
}

.company-card {
  border-radius: 25px;
}

.card-img-top {
  border-radius: 25px;
}

.blogcard-col {
  padding-bottom: 25px;
}

.about-row {
  padding-left: 2%;
  margin-top: 0px;
}

.about-col {
  /* margin-top: 50px; */
  width: 100%;
}

.about-img {
  width: 100%;
  box-shadow: 14px 14px 60px -10px rgb(0 0 0 / 40%);
}

.amy-intro-header {
  font-size: 3rem;
  margin-top: -20px;
  color: #999;
}

.hey-there-header {
  font-size: 1.2rem;
  font-style: italic;
}

.impact-tag {
  border-radius: 25px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
  color: white;
  width: fit-content;
  margin-bottom: 2px;
  margin-top: 2px;
  box-shadow: 2px 2px 2px #999;
}

.impact-category-tag {
  /* border: 1px solid #FCD947; */
  background-color: #013950;
  /* color: black; */
}

.impact-subcategory-tag {
  /* border: 1px solid #F59070; */
  background-color: #F59070;
  /* color: black; */
}

.dropdown-div {
  margin-left: 10px;
  margin-top: 30px;
}

.category-dropdown-div{
  display: initial;
  margin-right: 20px;
}

.subcategory-dropdown {
  background-color: #013950;
  border-color: #013950;
  box-shadow: 2px 2px 2px #999;
}

.subcategory-dropdown:hover, .subcategory-dropdown:active, .subcategory-dropdown:focus {
  color: #ffffff !important;
  background-color: #F59070 !important;
  border-color: #F59070 !important;
}

.blogpost-title {
  font-feature-settings: "smcp";
  font-size: 2.5rem;
  padding-top: 100px;
  color: white;
  text-align: center;
}

.blogpost-proposition {
  color: white;
  text-align: center;
  padding-left: 25%;
  padding-right: 25%;
}

@media (max-width: 600px) {
  .blogpost-proposition {
    padding-left: 10%;
    padding-right: 10%;
  }
}


.blogpost-div {
  padding: 50px;
}

@media (max-width: 600px) {
  .blogpost-div {
    padding: 20px;
  }
}

.spinners {
  margin-top: 20px;
  text-align: center;
}

.investment-item {
  margin-bottom: 0;
  margin-left: 15px;
}

.investments-list {
  size: 12px;
}

.social-logos-header-item {
  margin-left: 5px;
  margin-right: 5px;
}

.social-logos-header {
  float: right;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: 25px;
}

/* @media (max-width: 600px) { */
    .social-logos-header {
        display:none !important;
        /* margin: 0 auto;
        padding-right: 15px; */
    }
    .new-feature-banner {
      width: 90% !important;
    }
    .social-logos-footer {
      display: block !important;
      text-align: center;
      margin: 0 auto;
    }
/* } */

.new-feature-banner {
  border-radius: 25px;
  box-shadow: 5px 5px 5px #999;
  border: 1px solid rgba(0,0,0,.125);
  width: 60%;
  margin: 0 auto;
  display: block;
}

.footer {
  margin-top: 20px;
  margin-bottom: 20px;
}

.social-logos-footer {
  display:none;
}

.feedback-header {
  font-size: 22px;
}

.blogpost-container {
  max-width: 740px;
  margin: 0 auto;
  display: block;
}

.blogpost-header {
  font-size: 50px;
}

.blogpost-header-img {
  box-shadow: 5px 5px 5px #999;
  border-radius: 25px;
  width: 100%;
  margin-bottom: 30px;
}

.blogpost-image-col {
  margin: 0 auto;
  display: block;
}

@media (max-width: 800px) {
  .blogpost-container {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
  
  .blogpost-img {
    margin-top: 20px !important;
  }

  .blogpost-img-row {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .img-caption {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}

.blogpost-subheader {
  font-weight: 200;
}

.blogpost-header-container {
  margin-bottom: 30px;
}

.blogpost-date-author-item {
  font-weight: 300;
  margin-bottom: 0px;
}

.breakpoint-dots {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
  font-size: 24px;
}

.blog-quote {
  font-weight: 200;
  margin-left: 50px;
  margin-right: 50px;
  text-align: right;
  margin-top: 30px;
  margin-bottom: 30px;
  color: grey;
  font-style: italic;
}

.interview-question {
  margin-bottom: 30px;
}

.interview-question-q {
  font-size: 24px;
}

.blogpost-img {
  width: 100%;
}

.blogpost-img-row {
  margin-left: -80px;
  margin-right: -80px;
}

.img-caption {
  text-align: center;
  font-style: italic;
  margin-bottom: 20px;
  margin-top:10px;
  margin-left: 30px;
  margin-right: 30px;
  font-size: 14px;
}

.large-img-caption {
  margin-left: 130px;
  margin-right: 130px;
}

.blog-link {
  color: #013950;
  text-decoration: underline;
}

.center-img-div {
  margin: 0 auto;
}

.blogpost-video-container {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
}

.blogpost-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.dropdown-top-item {
  font-weight: bold
}

.waitlist-signup-input{
  margin-left: 10%;
  margin-right: 10%;
}

.find-job-header {
  padding-top: 100px;
  text-align: center;
  font-size: 3.5rem;
}

.find-job-subheader {
  text-align: center;
  font-weight: 300;
}

.sign-up-button {
  margin-left:20px;
  color: white;
  border-radius: .25rem !important;
  height: 100%;
}

.signup-group {
  max-width: 485px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 40px;
}

.signup-input {
  border-radius: .25rem !important;
}

@media (max-width: 700px) {
  .sign-up-button {
    /* width: 395px; */
    margin-top: 10px !important;
    margin-left: 0px !important;
    flex: 0 0 100%;
  }

  .signup-btn-div {
    flex: 0 0 100%;
  }
}

.top-10-company-title {
  padding-top: 20px;
  font-size: 24px;
}

.top-10-company-logo {
  width: 20%;
  border-radius: 20%;
}

@media (max-width: 600px) {
  .top-10-company-logo {
    width: 40%;
    display: block;
    margin: 0 auto;
  }
}

.article-src-text {
  font-size: 12px;
}

.buymeacoffee-img{
  width: 50%;
  margin: 0 auto;
}

.author-avatar {
    width: 48px;
    height: 48px;
    border-radius: 50%;
}

.author-header {
  display: flex;
  margin-top: 20px;
}

.blogpost-date-author {
  margin-left: 8px;
}

.company-entry-count {
  margin-top: 10px;
  margin-left: 10px;
}

.jobseeker_image {
  margin-right: auto;
  margin-left: auto;
  margin-top: 100px;
  display: block;
  width: 60%;
}

.working-woman-div {
  margin-top: 70px;
  background: #013950;
}

.working-woman-img {
  width: 50%;
}

.category_icons {
  margin-right: auto;
  margin-left: auto;
  display: block;
  width: 60%;
}

.category_icon_div {
  background: #013950;
  padding-top: 30px;
  margin-top: 100px;
  padding-bottom: 50px;
  margin-bottom: 50px;
  border-radius: 25px;
}

.category_choice {
    color: white;
    text-align: center;
    font-size: 24px;
}

.logo-text-div {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.logo-text {
  font-size: 1.5rem;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  'sans-serif'; */
}

.logo-div {
  height: 50px;
}

.section-1 {
  height: 700px;
  padding-top: 70px;
  background-color: #DDF8E2;
}

.section-2 {
  background-color: white;
  padding-top: 200px;
  padding-bottom: 200px;
}

.section-3 {
  background-color: #013950;
}

.ppp-img {
  height: 450px;
}

@media (max-width: 600px) {
  .ppp-img {
    height: 350px;
    padding-top: 20px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

.generic-link {
  color: black;
}

.generic-link:hover {
  color: #F59070;
}

.blog-card-link {
  color: white;
}

.greendrop-page {
  background-color: #DDF8E2;
  min-height: 800px;
  padding-top: 50px;
}

.bluedrop-page {
  background-color: #013950;
  min-height: 800px;
  padding-top: 50px;
}

.whitedrop-page {
  min-height: 800px;
  padding-top: 50px;
}

.job-item {
  margin-top: 50px;
}

.job-item p {
  margin-top: 0;
  margin-bottom: 0;
}

.job-category-item {
  margin-bottom: 50px;
}

.job-board-div {
  margin-left: 50px;
  margin-right: 50px;
  padding-top: 20px;
}

.select-pill input {
  visibility: hidden;
  width: 0px;
}

.select-pill {
  border-radius: 25px;
  color: black;
  margin-right: 5px;
}

.category-pill-group {
  margin: 50px;
}

.pill-icon {
  margin-left: 5px;
}

.role-category-pill {
  border-color: #013950 !important;
}

.role-category-pill .pill-icon {
  color: #013950;
}

.jobs-pill-checkbox:not(:checked) ~ .role-category-pill:hover, 
.jobs-pill-checkbox:not(:checked) ~ .role-category-pill:focus, 
.jobs-pill-checkbox:checked ~ .role-category-pill {
  background-color: #013950 !important;
  color: white;
}

.value-category-pill {
  border-color: #F59070 !important;
}

.value-category-pill .pill-icon {
  color: #F59070;
}

.jobs-pill-checkbox:not(:checked) ~ .value-category-pill:hover, 
.jobs-pill-checkbox:not(:checked) ~ .value-category-pill:focus, 
.jobs-pill-checkbox:checked ~ .value-category-pill {
  background-color: #F59070 !important;
  color: white;
}

.aggregate-value-category-pill {
  border-color: #FCD947 !important;
}

.aggregate-value-category-pill .pill-icon {
  color: #FCD947;
}

/* .aggregate-value-category-pill:hover, .aggregate-value-category-pill:active, .aggregate-value-category-pill:focus, 
.aggregate-value-category-pill:not(:disabled):not(.disabled).active, .jobs-pill-checkbox:checked ~ label{
  background-color: #FCD947 !important;
  color: black;
} */

.jobs-pill-checkbox:not(:checked) ~ .aggregate-value-category-pill:hover, 
.jobs-pill-checkbox:not(:checked) ~ .aggregate-value-category-pill:focus, 
.jobs-pill-checkbox:checked ~ .aggregate-value-category-pill {
  background-color: #FCD947 !important;
  color: black;
}

.jobs-pill-checkbox:not(:checked) ~ .select-pill:hover .pill-icon, 
.jobs-pill-checkbox:not(:checked) ~ .select-pill:focus .pill-icon, 
.jobs-pill-checkbox:checked ~ .select-pill .pill-icon  {
  color: white;
}

.job-matching-header {
  margin-top: 50px;
  margin-bottom: 150px;
  margin-left: 50px;
  margin-right: 50px;
  text-align: center;
}

.job-matches-form {
  margin-top: 50px;
  margin-bottom: 100px;
}

.job-card-image {
  margin: auto;
  margin-left: 20px;
  margin-right: 20px;
  width: 7%;
  height: 7%;
  box-shadow: 5px 5px 5px #999;
  background-color: white;
  border-radius: 100%;
}

.job-card {
  flex-direction: row;
  /* overflow: scroll; */
  margin-right: 20px;
}

@media (max-width: 600px) {
  .job-card-image {
    width: 20%;
    height: 20%;
    margin-left: 10px;
    margin-right: 10px;
  }
  .job-board-div {
    margin-left: 20px;
    margin-right: 20px;
  }
}

.job-matches-form-div {
  margin-left: 10%;
  margin-right: 10%;
}

.job-tag {
  float: left;
  margin-right: 10px;
}

.job-tags-div {
  padding-top: 10px;
  border-top: 1px solid lightgrey;
}

.job-card-body {
  margin-bottom: 10px;
}

.location-svg { 
  width: 20px;
}

.jobs-pill-checkbox {
  display: none;
}

.jobs-request-again-button {
  color: white;
}

.jobs-request-again-div {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 50px;
}

.job-board-no-results-div {
  text-align: center;
  margin-top: 100px;
  margin-bottom: 150px;
}

.no-results-image {
  width: 100px;
}

.accordion-button {
  width: 100%;
  border: solid 1px lightgrey;
  background: #013950;
  color: white;
  border-radius: 25px;
}

.jobs-match-accordian {
  margin-left: 150px;
  margin-right: 150px;
  margin-bottom: 20px;
  border: solid 1px lightgrey;
  border-radius: 25px;
}

@media (max-width: 600px) {
  .jobs-match-accordian {
    margin-left: 30px;
    margin-right: 30px;
  }
}